import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet en forma de pota de cavall, de 10-40 cm d’ample per 10-20 cm d’altura, enganxat directament al tronc de l’arbre i prolongant-se cap amunt, formant unes amples zones concèntriques de creixement anual. Està protegit per una dura crosta de color cendrós. Té tubs llargs, de color marró i els porus són petits, primer cremosos, després més foscos. Les espores són blanques en massa, allargades, de 14-20 x 5-7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      